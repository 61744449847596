export const formatTimeCountdown = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = seconds % 60;
  return `${minutes}:${secondsRemaining < 10 ? "0" : ""}${secondsRemaining}`;
};

export const formatNumber = (number: number) => {
  if (number === null || number === undefined) {
    return "0";
  }
  return number.toLocaleString();
};
